import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { Props as InputProps } from "./Input";

import Input from "./Input";

const InputGroupStyled = styled.div`
  position: relative;
`;

interface IconProps {
  theme?: DefaultTheme;
}

const Icon = styled.div<IconProps>`
  position: absolute;
  margin-top: -2px;
  top: 50%;
  left: 27px;
  font-size: 19px;
  color: ${({ theme }) => rgba(theme.colors.dark, 0.4)};
  transform: translateY(-50%);
`;

interface Props extends IconProps, InputProps {
  type?: string;
  focusColor?: keyof DefaultTheme["colors"];
  icon?: React.ReactNode;
}

const InputGroup: React.FC<Props> = ({
  type = "text",
  focusColor = "secondary",
  icon = <span className="far fa-envelope" />,
  ...rest
}) => {
  return (
    <InputGroupStyled>
      <Input
        width="100%"
        type={type}
        textColor="text"
        bg="light"
        focusColor={focusColor}
        pl="4.125rem"
        {...rest}
      />
      <Icon className="d-flex align-items-center">{icon}</Icon>
    </InputGroupStyled>
  );
};

export default InputGroup;
