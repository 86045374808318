import React from "react";
import styled, { DefaultTheme } from "styled-components";
import {
  color,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  style,
  ResponsiveValue,
} from "styled-system";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

interface StyledProps
  extends SpaceProps,
    TypographyProps,
    ShadowProps,
    LayoutProps,
    BorderProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
  width?: string | number;
  height?: string | number;
  bg?: string;
  css?: unknown;
  size?: number;
}

interface ThemeProps {
  focusColor: keyof DefaultTheme["colors"];
}

const InputSolid = styled.input<StyledProps & ThemeProps>`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  display: block;
  padding: 1.275rem 1.75rem;
  background-clip: padding-box;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  border-radius: 10px;
  ${color}
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
  ${layout};
  ${border};
  &:focus,
  &:active,
  &.active {
    border-color: ${({ theme, focusColor }) => theme.colors[focusColor]};
    outline: 0;
    box-shadow: none;
  }
`;

interface InputAnimationProps extends StyledProps {
  theme: DefaultTheme;
}

const InputAnimation = styled.div<InputAnimationProps>`
  position: relative;
  ${color}
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
  ${layout};
  ${border};
  input {
    width: 100%;
    padding: 1.275rem 1rem;
    border: ${({ theme }) => `1px solid ${theme.colors.border}`};
    background-color: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.dark};
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: -0.56px;
    border-radius: 10px;
    background-clip: padding-box;
    transition: all 0.3s ease-out;
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
      outline: none;
    }
  }
  input:focus ~ label {
    top: 0px;
    left: 15px;
  }

  label {
    background-color: ${({ theme }) => theme.colors.light};
    font-size: 18px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.darkShade};
    top: 50%;
    padding: 0 10px;
    left: 15px;
    border-radius: 5px;
    margin-bottom: 0;
    transform: translateY(-50%);
    position: absolute;
    transition: 0.4s;
    pointer-events: none;
  }
`;

export interface Props extends StyledProps {
  variant?: string;
  type?: string;
  focusColor?: keyof DefaultTheme["colors"];
  placeholder?: string;
  css?: unknown;
  name?: string;
  required?: boolean;
  id?: string;
  rows?: number;
  className?: string;
  hidden?: boolean;
  as?: string;
}

const Input: React.FC<Props> = ({
  variant = "solid",
  type = "text",
  focusColor = "secondary",
  placeholder,
  css,
  ...rest
}) => {
  return variant === "animation" ? (
    <InputAnimation css={css} {...rest}>
      <input
        width="100%"
        type={type}
        color="text"
        // @ts-expect-error html input element doesn't expect bg attribute
        bg="light"
      />
      <label>{placeholder}</label>
    </InputAnimation>
  ) : (
    <InputSolid
      width="100%"
      type={type}
      textColor="text"
      bg="light"
      placeholder={placeholder}
      focusColor={focusColor}
      css={css}
      {...rest}
    />
  );
};

export default Input;
