import React from "react";
import { uniqueId } from "lodash";
import styled from "styled-components";
import {
  color,
  space,
  SpaceProps,
  border,
  BorderProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
} from "styled-system";
import { DefaultTheme } from "styled-components";

interface CheckStyledProps
  extends SpaceProps,
    BorderProps,
    TypographyProps,
    ShadowProps,
    FlexboxProps,
    LayoutProps {
  bg?: string;
}

interface ThemeProps {
  theme: DefaultTheme;
}

const CheckStyled = styled.label<CheckStyledProps & ThemeProps>`
  display: inline-flex;
  cursor: pointer;

  input:checked ~ .check::before {
    content: "\f14a";
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    color: ${({ theme }) => theme.colors.secondary};
  }
  ${color}
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`;

const Check = styled.span`
  position: relative;
  line-height: 1;
  top: -2px;
  margin-right: 8px;
  min-height: 20px;
  min-width: 20px;
  &::before {
    content: "\f0c8";
    font-family: "Font Awesome 5 free";
    font-size: 19px;
    color: #e2e4e8;
  }
`;

const Text = styled.p<ThemeProps>`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;
`;

interface Props extends CheckStyledProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

const Checkbox: React.FC<Props> = ({
  children = "Keep me signed in",
  onClick = () => null,
  ...rest
}) => {
  const uID = uniqueId("check_");

  return (
    <CheckStyled {...rest} htmlFor={uID}>
      <input className="d-none" type="checkbox" id={uID} />
      <Check className="check"></Check>
      <Text>{children}</Text>
    </CheckStyled>
  );
};

export default Checkbox;
