import React from "react";
import styled from "styled-components";
import {
  color,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  style,
  ResponsiveValue,
} from "styled-system";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

interface ParagraphProps extends SpaceProps, TypographyProps, ShadowProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
  bg?: string;
  className?: string;
}

const Paragraph = styled.a<ParagraphProps>`
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 35px;
  ${color};
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
  &:hover,
  &:active,
  &.active {
    ${textColor}
    ${color};
    text-decoration: none;
  }
`;

interface Props extends ParagraphProps {
  href: string;
  color?: string;
  onClick?(e: React.MouseEvent): void;
  children: React.ReactNode;
}

const A: React.FC<Props> = ({ href = "#", color = "secondary", ...props }) => {
  return <Paragraph href={href} color={color} {...props} />;
};

export default A;
