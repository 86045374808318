import React, { useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import {
  color,
  space,
  SpaceProps,
  border,
  BorderProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
} from "styled-system";

interface StyledProps
  extends SpaceProps,
    BorderProps,
    TypographyProps,
    ShadowProps,
    FlexboxProps,
    LayoutProps {}

interface SwitchStyledProps extends StyledProps {
  active: unknown;
  theme: DefaultTheme;
  bg: keyof DefaultTheme["colors"];
}

const SwitchStyled = styled.div<SwitchStyledProps>`
  display: inline-block;
  cursor: pointer;
  width: 72px;
  height: 33px;
  border-radius: 17px;
  transition: all 0.3s ease-out;
  background: ${({ active, theme, bg }) =>
    active ? theme.colors[bg] : `#eae9f2`};
  position: relative;
  margin: 0 15px;
  white-space: nowrap;
  ${color}
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`;

interface RoundProps extends StyledProps {
  active: unknown;
}

const Round = styled.span<RoundProps>`
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  position: absolute;
  transition: all 0.3s ease-out;
  left: ${({ active }) => (active ? `calc(100% - 33px)` : `0`)};
  margin-left: 6px;
  top: 0;
  margin-top: 6px;
  transition: 0.4s;
  border-radius: 500px;
  pointer-events: none;
`;

interface Props extends StyledProps {
  bg?: keyof DefaultTheme["colors"];
  onClick: () => void;
  [propName: string]: unknown;
}

const Switch: React.FC<Props> = ({
  bg = "secondary",
  onClick = () => null,
  ...rest
}) => {
  const [active, setActive] = useState<boolean>(false);

  const handleSwitch = (): void => {
    setActive(!active);
    onClick();
  };

  return (
    <SwitchStyled
      bg={bg}
      {...rest}
      onClick={handleSwitch}
      active={active ? 1 : 0}
    >
      <Round active={active ? 1 : 0} />
    </SwitchStyled>
  );
};

export default Switch;
