interface Items {
  name: string;
  label: string;
  items?: Items[];
  isExternal?: boolean;
}

export const menuItems: {
  name: string;
  label: string;
  items?: Items[];
  isExternal?: boolean;
}[] = [
  {
    name: "demos",
    label: "Who We Help",
    items: [
      // { name: "bids-and-councils", label: "BIDs, CRPs & Councils" },
      // {
      //   name: "shopping-centres-and-parks",
      //   label: "Shopping Centre & Parks",
      // },
      // {
      //   name: "retailers-hospitality",
      //   label: "Retailers & Hospitality",
      // },
      {
        name: "who-we-help/BIDs-and-Councils",
        label: "BIDs, CRPs & Councils",
      },
      {
        name: "who-we-help/shoppingCentre-parks",
        label: "Shopping Centre & Parks",
      },
      {
        name: "who-we-help/retailers-hospitality",
        label: "Retailers & Hospitality",
      },
    ],
  },
  {
    name: "pages",
    label: "How We Help",
    items: [
      { name: "shopwatch-and-townlink", label: "ShopWatch & TownLink" },
      { name: "alert", label: "Alert! Crime Reporting App" },
      { name: "smart-radio", label: "Smart Radio & Cameras" },
      {
        name: "internal-communication",
        label: "Internal Communication",
      },
    ],
  },
  {
    name: "training",
    label: "Training",
  },
  {
    name: "contact/",
    label: "Contact Us",
  },
];
