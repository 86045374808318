import React from "react";
import { withTheme, DefaultTheme } from "styled-components";

import Select from "react-select";

const defaultOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

interface CustomStylesTheme {
  theme: DefaultTheme;
}

const getCustomStyles = (theme: DefaultTheme) => {
  return {
    option: (
      provided: { [name: string]: any },
      state: { [name: string]: any }
    ) => ({
      ...provided,
      color: state.isSelected ? theme.colors.secondary : theme.colors.dark,
      backgroundColor: theme.colors.light,
    }),
    control: (
      provided: { [name: string]: any },
      state: { [name: string]: any }
    ) => {
      return {
        ...provided,
        border:
          state.menuIsOpen || state.isFocused
            ? `1px solid ${theme.colors.secondary} !important`
            : `1px solid ${theme.colors.border} !important`,
        borderRadius: 10,
        padding: "1.275rem 1rem",
        outline: "none",
        boxShadow: "none",
      };
    },
  };
};

interface Props {
  theme: DefaultTheme;
  name?: string;
  options?: { value: string; label: string }[];
  [propName: string]: unknown;
}

const SelectStyled: React.FC<Props> = ({
  theme,
  name = "item",
  options = defaultOptions,
  ...rest
}) => {
  return (
    <Select
      styles={getCustomStyles(theme)}
      defaultValue={options[1]}
      name={name}
      options={options}
      {...rest}
    />
  );
};

export default withTheme(SelectStyled);
