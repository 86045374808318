import styled from "styled-components";
import {animated} from 'react-spring'

import {
  color,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  typography,
  TypographyProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  style,
  ResponsiveValue,
  OpacityProps
} from "styled-system";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});


export interface BoxProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    GridProps,
    TypographyProps,
    BackgroundProps,
    BorderProps,
    PositionProps,
    ShadowProps,
    OpacityProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
  bg?: string;
  css?: string;
}

const Box = styled(animated.div)<BoxProps>`
  box-sizing: border-box;
  min-width: 0;
  ${color};
  ${textColor};
  ${space};
  ${layout};
  ${flexbox};
  ${grid};
  ${typography};
  ${background};
  ${border};
  ${position};
  ${shadow};
`;

export default Box;
