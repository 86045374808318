import React from "react";
import styled, { DefaultTheme } from "styled-components";
import {
  color,
  background,
  BackgroundProps,
  space,
  BorderProps,
  border,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  style,
  ResponsiveValue,
} from "styled-system";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

export interface BadgeSolidProps
  extends BackgroundProps,
    BorderProps,
    TypographyProps,
    ShadowProps,
    FlexboxProps,
    LayoutProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
  mr?: number;
  mb?: number;
  ml?: number;
  px?: number;
  py?: number;
}

const BadgeSolid = styled.span<BadgeSolidProps>`
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.41px;
  border-radius: 5px;
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${color}
  ${textColor};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`;

interface Props extends BadgeSolidProps {
  color?: string;
  bg?: keyof DefaultTheme["colors"];
}

const Badge: React.FC<Props> = ({
  color = "light",
  bg = "primary",
  ...rest
}) => {
  return (
    <BadgeSolid
      textColor={color}
      border={`1px solid`}
      borderColor={bg}
      background={bg}
      {...rest}
    />
  );
};

export default Badge;
