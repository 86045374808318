import React, { useState } from "react";

interface GlobalContextValue {
  themeDark: boolean;
  toggleTheme(): void;
  headerDark: boolean;
  goHeaderDark(): void;
  goHeaderLight(): void;
  footerDark: boolean;
  goFooterDark(): void;
  goFooterLight(): void;
  videoModalVisible: boolean;
  toggleVideoModal(): void;
  visibleOffCanvas: boolean;
  toggleOffCanvas(): void;
  closeOffCanvas(): void;
  cartTotal: number;
  incCartTotal(): void;
  decCartTotal(): void;
}

// non null assertion to tell typescript that there will be a value
const GlobalContext = React.createContext<GlobalContextValue>(null!);

interface ProviderProps extends GlobalContextValue {}

const GlobalProvider: React.FC<ProviderProps> = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [headerDark, setHeaderDark] = useState(false);
  const [footerDark, setFooterDark] = useState(true);
  const [cartTotal, setCartTotal] = useState(3);

  const toggleTheme = () => {
    setThemeDark(!themeDark);
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  const goHeaderDark = () => {
    setHeaderDark(true);
  };
  const goHeaderLight = () => {
    setHeaderDark(false);
  };

  const goFooterDark = () => {
    setFooterDark(true);
  };
  const goFooterLight = () => {
    setFooterDark(false);
  };

  const incCartTotal = () => {
    setCartTotal(cartTotal + 1);
  };
  const decCartTotal = () => {
    setCartTotal(cartTotal - 1);
  };

  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        headerDark,
        goHeaderDark,
        goHeaderLight,
        footerDark,
        goFooterDark,
        goFooterLight,
        videoModalVisible,
        toggleVideoModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        cartTotal,
        incCartTotal,
        decCartTotal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
